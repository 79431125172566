<template>
    <div class="headerWrapper">
        <ul id="nav1">
            <!-- <li :class="{ active: menuIndex == 0 }" @click="menuIndex = 0">
                <router-link to="/Search?active=0">
                    <p><img src="../../assets/images/total.png" /></p>
                    统计
                </router-link>
            </li>
            <li :class="{ active: menuIndex == 1 }" @click="menuIndex = 1">
                <router-link to="/Query?active=1">
                    <p><img src="../../assets/images/search.png" /></p>
                    查询
                </router-link>
            </li>
            <li :class="{ active: menuIndex == 2 }" @click="menuIndex = 2">
                <router-link to="/Map?active=2">
                    <p><img src="../../assets/images/map.png" /></p>
                    地图
                </router-link>
            </li> -->
            <!-- <li :class="{ active: menuIndex == 3 }" @click="menuIndex = 3">
          <a href="http://localhost:8012/index.html" target="_blank">
            <p><img src="/assets/images/contrast.png" /></p>
            套图叠加
          </a>
        </li> -->
            <!-- <li :class="{ active: menuIndex == 4 }" @click="menuIndex = 4">
                <router-link to="/Project?active=4">
                    <p><img src="../../assets/images/contrast.png" /></p>
                    项目库
                </router-link>
            </li>
            <li :class="{ active: menuIndex == 3 }">
                <a href="http://121.89.201.182:8085/index.html" target="_blank">
                    <p><img src="../../assets/images/contrast.png" /></p>
                    专题汇报
                </a>
            </li> -->
        </ul>
    </div>
</template>
<script>
export default {
    name: "Home",
    data() {
        return {
            menuIndex: 0, //菜单序号
        };
    },
    mounted(){
      this.menuIndex = this.$route.query.active;
    },
    methods: {
        getPathFun() {
            this.menuIndex = this.$route.query.active;
        },
    },
    components: {},
    watch: {
        $route: "getPathFun",
    },
};
</script>
<style lang="less" scoped>
#nav1 {
    float: initial !important;
    display: table;
    width: 100%;
    margin-left: 0;
    a{
        display: flex;
        font-size: 18px;
        padding: 10px 20px;
        p{
            margin-bottom: 0;
        }
        img{
            width: 20px;
            position: relative;
            top: -2px;
            margin-right: 5px;
        }
    }
}
</style>
