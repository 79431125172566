<template>
  <div>
    <el-form v-if="model != null" :model="model">
      <el-form-item label="项目名称：" :label-width="'120px'">
        <el-input
          style="width: 90%"
          v-model="model.XMMC"
          autocomplete="off"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="状态：" :label-width="'120px'">
        <el-cascader
          style="width: 90%"
          v-model="qqgzjz"
          :options="qqgzjzs"
          placeholder="前期工作进展"
          :props="{ expandTrigger: 'hover' }"
          collapse-tags
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// import Map from "./Map.vue";
export default {
  props: ["model"],
  data() {
    return {
      qqgzjz: [], //前期工作进展
      qqgzjzs: [
        {
          value: "未开展前期工作",
          label: "未开展前期工作",
        },
        {
          value: "前期工作进行中",
          label: "前期工作进行中",
          children: [
            {
              value: "工可研-报告编制中",
              label: "工可研-报告编制中",
            },
            {
              value: "工可研-报告编制已完成",
              label: "工可研-报告编制已完成",
            },
            {
              value: "工可研-已评审待批复",
              label: "工可研-已评审待批复",
            },
            {
              value: "工可研-已批复",
              label: "工可研-已批复",
            },
            {
              value: "初步设计-编制中",
              label: "初步设计-编制中",
            },
            {
              value: "初步设计-评审待批复",
              label: "初步设计-评审待批复",
            },
            {
              value: "初步设计-已批复",
              label: "初步设计-已批复",
            },
          ],
        },
        {
          value: "前期工作已完成",
          label: "前期工作已完成",
        },
      ],
    };
  },
  mounted() {
    console.log(this.model);
    this.qqgzjz = this.model.QQGZJZ.split("|");
    console.log(this.qqgzjz);
  },
  methods: {
    valueChange(value) {
      var that = this;
      console.log(value);
      if (value.length == 1) {
        this.model.QQGZJZ = value[0];
      }
      if (value.length == 2) {
        this.model.QQGZJZ = value[0] + "|" + value[1];
      }
      var postData = {
        id: this.model.ID,
        qqgzjz: this.model.QQGZJZ,
      };
      this.http.post("/api/Plan_road/updateQqgzjz", postData).then((res) => {
        if (res.status) {
          that.$message.info("状态更新成功!");
          that.$emit("qqgzjz_change");
        } else {
          that.$message.info("状态更新失败!");
        }
      });
    },
  },
};
</script>
<style scoped>
</style>