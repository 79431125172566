<template>
    <div>
        <Header v-show="dialogIs" />

        <div class="navbar" v-show="dialogIs">
            <el-link @click="activeIndex = 1" :class="{ active: activeIndex == 1 }" :underline="false">公路</el-link>
            <el-link @click="activeIndex = 2" :class="{ active: activeIndex == 2 }" :underline="false">机场</el-link>
            <el-link @click="activeIndex = 3" :class="{ active: activeIndex == 3 }" :underline="false">枢纽站场</el-link>
            <div class="toolscontainer">
                <el-link icon="el-icon-full-screen " @click="fullScreen" :underline="false"><span> 全屏</span></el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link icon="el-icon-delete-solid" @click="clearGraphic" :underline="false"><span> 清除</span></el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link :style="{ color: measureDistanceState ? '#008fd4' : '' }" icon="iconfont icon-changdu" :underline="false" @click="
            measureDistanceState = !measureDistanceState;
            measureDistance(measureDistanceState);
          ">测距</el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link :style="{ color: measureAreaState ? '#008fd4' : '' }" icon="iconfont icon-area" :underline="false" @click="
            measureAreaState = !measureAreaState;
            measureArea(measureAreaState);
          ">测面</el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link :style="{ color: bgLayerState ? '#008fd4' : '' }" icon="iconfont icon-a-ziyuan29 " @click="
            bgLayerState = !bgLayerState;
            bgLayerShow(bgLayerState);
          " :underline="false"><span>卫星</span></el-link>
                <el-divider direction="vertical"></el-divider>
                <!-- <el-link
          :style="{ color: planLayerState ? '#008fd4' : '' }"
          icon="el-icon-s-open"
          @click="
            planLayerState = !planLayerState;
            planLayerShow(planLayerState);
          "
          ><span>规划</span></el-link
        >
        <el-divider direction="vertical"></el-divider> -->
                <!-- <el-link
          :style="{ color: backgrondLayerState ? '#008fd4' : '' }"
          icon="iconfont icon-luwang"
          @click="
            backgrondLayerState = !backgrondLayerState;
            backgroundLayerShow(backgrondLayerState);
          "
          :underline="false"
          ><span> 路网</span></el-link
        >
        <el-divider direction="vertical"></el-divider> -->
                <!-- <el-dropdown>
          <span class="el-dropdown-link">
            <i class="el-icon-s-cooperation"></i> 更多<i
              class="el-icon-arrow-down el-icon--right"
            ></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="iconfont icon-zhuanghao"
              >测桩号</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown> -->
            </div>
        </div>
        <el-row class="cont-box">
            <!-- <keep-alive>
        <router-view></router-view>
      </keep-alive> -->
            <Road v-show="activeIndex == 1 && dialogIs" ref="road" v-on="{
          findPorject: findPlanRoad,
          findRoad: findRoad,
          planFilter: planFilter,
          search: fullScreen,
        }" />
            <Airport v-show="activeIndex == 2" ref="airport" v-on="{
          findPorject: findPlanAirport,
          airportFilter: airportFilter,
          search: fullScreen,
        }" />
            <Hinges v-if="activeIndex == 3" ref="hinges" :datas="datas" v-on="{
          findPorject: findHinge,
          findHinges: findHinges,
          airportFilter: airportFilter,
          search: fullScreen,
        }" />
            <!-- 地图 -->
            <Map ref="map" v-on="{
          mapchange: mapchange,
          singleclick: findPlanRoadInfo,
          roadclick: findBaseRoadInfo,
          hingeclick: queryHinge,
          queryRoad: queryRoad,
        }" />
        </el-row>
    </div>
</template>
<script>
import Map from "./Map.vue";
import Road from "./Road.vue";
import Airport from "./Airport.vue";
import Hinges from "./Hinges.vue";
import Header from "@/views/Home/Header.vue";

export default {
    props: ["dialogData"],
    components: {
        Map,
        Road,
        Airport,
        Header,
        Hinges,
    },
    data() {
        return {
            datas: {},
            dialogDatas: {},
            dialogIs: true,
            activeIndex: 1,
            bgLayerState: false, //卫星图层显示状态
            backgrondLayerState: true, //路网图层显示状态
            planLayerState: false, //规划图层显示状态
            measureDistanceState: false, //测量距离显示状态
            measureAreaState: false, //测量面积显示状态
        };
    },
    mounted() {
        console.log(this.$route.path);
        if (this.$route.path == "/Query" || this.$route.path == "/Road/Project" || this.$route.path == "/Hinge/Project"|| this.$route.path == "/Road/Bridge") {
            this.dialogDatas = this.dialogData;
            this.dialogIs = false;
            this.getFData();
        }
    },
    watch: {
        dialogData(e) {
            console.log("00000");
            console.log(e);
            this.dialogDatas = e;
            this.dialogIs = false;
            this.getFData();
        },
        $route: "getPathFun",
    },
    methods: {
        getFData() {
            if (!this.dialogDatas.GLLX) {
                this.findHinges([this.dialogDatas.XH])
                this.$refs.map.findHinge(this.dialogDatas.XH);
            } else {
                this.findPlanRoad(this.dialogDatas);
                // var postData = {
                //     page: 1,
                //     rows: 30,
                //     Sort: "DSBM",
                //     Order: "asc",
                //     wheres: JSON.stringify([
                //         {
                //             Name: "XMMC",
                //             Value: this.dialogDatas.XMMC,
                //             DisplayType: "like",
                //         },
                //         { Name: "SSDS", Value: "", DisplayType: "Equal" },
                //         { Name: "SSX", Value: "", DisplayType: "Equal" },
                //         { Name: "XMND", DisplayType: "Equal" },
                //         { Name: "QQGZJZ", Value: "", DisplayType: "checkbox" },
                //         { Name: "GLLX", DisplayType: "Equal" },
                //         { Name: "XMXZ", Value: "", DisplayType: "checkbox" },
                //         { Name: "JSXZ", Value: "", DisplayType: "checkbox" },
                //         { Name: "XMLX", Value: "", DisplayType: "checkbox" },
                //     ]),
                // };
                // console.log(postData);

                // this.http.post("/api/Plan_road/getXh", postData).then((res) => {
                //     var filter = "1<>1"; //设置默认条件，无结果时不渲染
                //     if (res.length > 0) {
                //         var str = "";
                //         res.forEach((v) => {
                //             str += "'" + v.xh + "',";
                //         });

                //         str = str.substr(0, str.length - 1);
                //         filter = "OBJECTID IN (" + str + ")";
                //     }
                //     console.log(filter, 1111111111111);
                //     this.planFilter(filter);
                // });
            }
        },

        fullScreen() {
            this.$refs.map.map.getView().fit(this.$refs.map.extent);
        },
        clearGraphic() {
            this.$refs.map.clearGraphic();
        },
        bgLayerShow(state) {
            this.$refs.map.bgLayerShow(state);
        },
        planLayerShow(state) {
            this.$refs.map.planLayerShow(state);
        },
        airportLayerShow(state) {
            this.$refs.map.airportLayerShow(state);
        },
        backgroundLayerShow(state) {
            this.$refs.map.backgroundLayerShow(state);
        },
        measureDistance(state) {
            this.$refs.map.measureDistance(state);
        },
        measureArea(state) {
            this.$refs.map.measureArea(state);
        },
        //地图监听事件
        mapchange() {
            //当缩放地图时，隐藏导航
            // if (this.activeIndex == 1) {
            //     if (this.$refs.road.listboxShow) {
            //         this.$refs.road.listtotalShow = true;
            //         this.$refs.road.listboxShow = false;
            //     }
            // }
            // if (this.activeIndex == 2) {
            //     if (this.$refs.airport.listboxShow) {
            //         this.$refs.airport.listtotalShow = true;
            //         this.$refs.airport.listboxShow = false;
            //     }
            // }
        },
        queryHinge(res) {
            console.log("hingeclick", res);
            this.datas = res;
        },
        queryRoad(features) {
            console.log("features", features);
            var list = [];
            features.forEach((element) => {
                var item = {
                    lxbm: element.values_.LXBM,
                    lxmc: element.values_.LXMC,
                    qdzh: element.values_.Qdzh,
                    zdzh: element.values_.Zdzh,
                    ldlc: parseFloat(
                        element.values_.Zdzh - element.values_.Qdzh
                    ).toFixed(3),
                    jsdj: element.values_.Ldjsdj,
                    lmlx: element.values_.Ldlmlx,
                };
                list.push(item);
            });
            this.$refs.road.showRoadList(list);
        },
        getPathFun() {
            if (this.$route.query.active) {
                this.dialogIs = true;
            }
        },
        findRoad(xh) {
            this.$refs.map.findRoad(xh);
        },
        findHinge(xh) {
            this.$refs.map.findHinge(xh);
        },
        //获取规划公路详情
        findPlanRoadInfo(res) {
            this.$refs.road.contentShow = false;
            this.$refs.road.roadShow = false;
            if (res != null) {
                this.$refs.road.findPlanRoadInfo(res.xm, res.type);
            }
        },
        findBaseRoadInfo(features) {
            this.$refs.road.showRoad(features);
        },
        findPlanAirport(xmmc) {
            this.$refs.map.findPlanAirport(xmmc);
        },
        //根据查询结果显示规划图层
        planFilter(highFilter, planFilter) {
            this.$refs.map.hingeSource.clear();
            this.$refs.map.airportLayer.setVisible(false);
            this.$refs.map.planFilter(highFilter, planFilter);
            this.$refs.map.airportLayer.setVisible(false);
            if (highFilter != "1<>1") {
                this.$refs.map.highLayer.setVisible(true);
            } else {
                this.$refs.map.highLayer.setVisible(false);
            }
            if (planFilter != "1<>1") {
                this.$refs.map.planLayer.setVisible(true);
            } else {
                this.$refs.map.planLayer.setVisible(false);
            }
        },
        airportFilter(filter) {
            console.log("airportFilter");
            this.$refs.map.airportFilter(filter);
            this.$refs.map.planLayer.setVisible(false);
            this.$refs.map.highLayer.setVisible(false);
            this.$refs.map.hingeSource.clear();
            this.$refs.map.airportLayer.setVisible(true);
        },
        findHinges(xhArray) {
            this.$refs.map.planLayer.setVisible(false);
            this.$refs.map.highLayer.setVisible(false);
            this.$refs.map.airportLayer.setVisible(false);
            this.$refs.map.findHinges(xhArray);
        },
        //地图定位，调用地图定位方法
        findPlanRoad(item) {
            //地图定位
            this.$refs.map.findPlanRoad(item);
        },
        //根据查询结果显示规划图层
        highFilter(filter) {
            this.$refs.map.highFilter(filter);
            this.$refs.map.airportLayer.setVisible(false);
            if (filter != "1<>1") {
                this.$refs.map.planLayer.setVisible(true);
            } else {
                this.$refs.map.planLayer.setVisible(false);
            }
        },
    },
};
</script>
<style scoped>
</style>
