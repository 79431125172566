<template>
    <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline map-form z-indextop">
            <el-form-item class="map-search">
                <div class="map-search-content">
                    <el-link class="sbtn-delete" icon="el-icon-delete" @click="
              currentPage = 1;
              clearCondition();
            " :underline="false" size="medium"></el-link>
                    <el-input size="medium" v-model="xmmc" clearable placeholder="请输入项目名称"></el-input>
                    <el-divider direction="vertical"></el-divider>
                </div>
                <el-button icon="el-icon-search" type="primary" size="medium" @click="
            currentPage = 1;
            search();
          " class="btn-search">搜索
                </el-button>
            </el-form-item>
            <el-form-item>
                <el-cascader size="medium" v-model="region" :options="regions" placeholder="行政区划" :props="{ expandTrigger: 'hover' }" collapse-tags clearable @change="valueChange">
                </el-cascader>
            </el-form-item>
            <el-form-item>
                <el-cascader size="medium" v-model="xmlx" :options="xmlxList" placeholder="项目类型" :props="{ expandTrigger: 'hover' }" collapse-tags clearable @change="handleChange">
                </el-cascader>
            </el-form-item>
            <el-form-item>
                <el-cascader size="medium" v-model="checkedXmfl" :options="xmflList" placeholder="项目分类" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable @change="valueChange">
                </el-cascader>
            </el-form-item>
        </el-form>
        <div class="left-box z-indextop">
            <div class="listtotal" v-show="listtotalShow" @click="
          listtotalShow = false;
          listboxShow = true;
        ">
                <el-link type="primary" :underline="false">共找到 {{ total }} 个搜索结果</el-link>
            </div>
            <div class="box" v-show="listboxShow">
                <div class="box-content">
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <div class="map-total-item">
                                <h5>项目数量</h5>
                                <b class="date-num">{{ total }}</b> <small>个</small>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="map-total-item">
                                <h5>占地面积</h5>
                                <b class="date-num">{{ total1 }}</b>
                                <small>平方米</small>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="list-item">
                        <div class="item" v-for="(item, index) in tableData" :key="index">
                            <h3>
                                <el-tag class="fright tit-tag" effect="plain" size="mini">{{item.XMLX}}</el-tag>
                                <a href="javascript:void(0);" @click="showInfo(item)">{{item.XMMC || item.ZHKYSNMC || item.KYZMC}}</a>
                            </h3>
                            <el-row>
                                <el-col :span="12">建设性质：{{ item.JSXZ }}</el-col>
                                <!-- <el-col :span="12">“十四五”投资(亿元)：{{ item.SSWTZ }}</el-col> -->
                            </el-row>
                            <el-row>
                                <el-col :span="12">所在地市：{{ item.SZDS }}</el-col>
                            </el-row>
                            <el-link type="primary" class="dingwei" v-if="!item.XH == ''" icon="el-icon-location-information" @click="findPorject(item.XH)">定位</el-link>
                        </div>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" layout="prev, pager, next" :page-size="pageSize" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="right-box map-box">
            <div class="map-info z-indextop">
                <div class="box" v-show="contentShow">
                    <div class="box-title1">
                        <h3 class="tit">项目详情</h3>
                        <span class="fright"><a href="javasciprt:void(0);" class="btn-down" v-show="!contentChildShow" @click="
                  contentChildShow = true;
                  introChildShow = false;
                " style="display: none"><i class="el-icon-arrow-down"></i></a>
                            <a href="javasciprt:void(0);" v-show="contentChildShow" @click="contentChildShow = false" class="btn-up"><i class="el-icon-arrow-up"></i></a><small> | </small><a href="javasciprt:void(0);" class="btn-close" @click="contentShow = false"><i class="el-icon-close"></i></a></span>
                    </div>
                    <div class="box-content box-content-sm" v-if="contentChildShow">
                        <el-descriptions :column="1" v-show="roadModel.XMMC">
                            <el-descriptions-item label="项目名称">{{ roadModel.XMMC}}</el-descriptions-item>
                            <el-descriptions-item label="所属城市">{{roadModel.SSCS}}</el-descriptions-item>
                            <el-descriptions-item label="分类">{{roadModel.FL }}</el-descriptions-item>
                            <el-descriptions-item label="建设性质">{{roadModel.JSXZ }}</el-descriptions-item>
                            <el-descriptions-item label="占地面积">{{roadModel.ZDMJ }}（亩）</el-descriptions-item>
                            <el-descriptions-item label="主体货类">{{roadModel.ZTHL }}</el-descriptions-item>
                            <el-descriptions-item label="计划实施年">{{ roadModel.JHSSNX }}</el-descriptions-item>
                            <el-descriptions-item label="建设计划">{{ roadModel.JSJH }}</el-descriptions-item>
                            <el-descriptions-item label="总投资">{{ roadModel.ZTZ }}（万元）</el-descriptions-item>
                            <el-descriptions-item label="项目级别">{{ roadModel.XMJB }}</el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" v-show="roadModel.ZHKYSNMC">
                            <el-descriptions-item label="综合客运枢纽名称">{{roadModel.ZHKYSNMC}}</el-descriptions-item>
                            <el-descriptions-item label="所属城市">{{roadModel.SSCS}}</el-descriptions-item>
                            <el-descriptions-item label="分类">{{roadModel.FL}}</el-descriptions-item>
                            <el-descriptions-item label="建设性质">{{roadModel.JSXZ}}</el-descriptions-item>
                            <el-descriptions-item label="枢纽总占地面积">{{roadModel.SNZZDMJ}}</el-descriptions-item>
                            <el-descriptions-item label="公路客运占地面积">{{roadModel.GLKYZDMJ}}</el-descriptions-item>
                            <el-descriptions-item label="公路客运建筑面积">{{roadModel.GLKYJZMJ}}</el-descriptions-item>
                            <el-descriptions-item label="公路客运战场级别">{{roadModel.GLKYZCJB}}</el-descriptions-item>
                            <el-descriptions-item label="计划实施(年限)">{{roadModel.JHSSNX}}</el-descriptions-item>
                            <el-descriptions-item label="建设计划">{{roadModel.JSJH}}</el-descriptions-item>
                            <el-descriptions-item label="总投资">{{roadModel.ZTZ}}（万元）</el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" v-show="roadModel.KYZMC">
                            <el-descriptions-item label="客运站名称">{{ roadModel.KYZMC}}</el-descriptions-item>
                            <el-descriptions-item label="位置">{{ roadModel.WZ}}</el-descriptions-item>
                            <el-descriptions-item label="建设性质">{{ roadModel.JSXZ}}</el-descriptions-item>
                            <el-descriptions-item label="总投资">{{ roadModel.ZTZ}}（万元）</el-descriptions-item>
                            <el-descriptions-item label="站场级别">{{ roadModel.ZCJB}}</el-descriptions-item>
                            <el-descriptions-item label="占地面积">{{ roadModel.ZDMJ}}（平方米）</el-descriptions-item>
                            <el-descriptions-item label="建筑面积">{{ roadModel.JZMJ}}（平方米）</el-descriptions-item>
                            <el-descriptions-item label="设计发送量">{{ roadModel.SJFSL}}（人/日）</el-descriptions-item>
                            <el-descriptions-item label="计划实施(年限)">{{ roadModel.JHSSNX}}（人/日）</el-descriptions-item>
                        </el-descriptions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: ["datas"],
    watch: {
        datas(e) {
            if (this.tableData) {
                this.contentChildShow = true;
                this.contentShow = true;
                this.tableData.forEach((element) => {
                    switch (this.xmlx[0]) {
                        case "货运枢纽":
                            if (element.XMMC == e["项目名"]) {
                                this.roadModel = element;
                            }
                            break;
                        case "客运枢纽":
                            if (element.ZHKYSNMC == e["项目名"]) {
                                this.roadModel = element;
                            }
                            break;
                        case "乡村运输服务站":
                            if (element.KYZMC == e["项目名"]) {
                                this.roadModel = element;
                            }
                            break;
                    }
                });
            } else {
                this.contentShow = false;
            }
        },
    },
    data() {
        return {
            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmfl: "", //项目分类
            xmlx: ["货运枢纽"], //项目类型
            checkedXmxz: [], //项目性质
            checkedJsxz: [], //建设性质
            checkedXmlx: [], //项目类型
            checkedXmfl: [], //项目类型
            region: [""], //已选中行政区划
            qqgzjz: [], //前期工作进展
            /**查询条件 end*/
            listboxShow: false, //搜索结果隐藏
            listtotalShow: false, //搜索结果条数隐藏
            total: 0, //请求记录数
            total1: 0, //请求记录数
            total2: 0, //请求记录数
            total3: 0, //请求记录数
            jsgm: 0,
            jsqmqs: 0,
            ztz: 0,
            sswtz: 0,
            tableData: [],
            resolution: 0,
            map: null,
            roadModel: null,
            contentShow: false, //项目详情显示
            contentChildShow: false, //项目详情最小化切换
            activeIndex: "1",
            input: "",
            checkList: [],
            checkAll: false,
            xmlxList: [
                { value: "货运枢纽", label: "货运枢纽" },
                { value: "客运枢纽", label: "客运枢纽" },
                { value: "乡村运输服务站", label: "乡村运输服务站" },
            ],
            xmflList: [
                {
                    value: "综合客运枢纽",
                    label: "综合客运枢纽",
                },
                { value: "县级客运站", label: "县级客运站" },
                {
                    value: "多式联运型货运枢纽（物流园区）",
                    label: "多式联运型货运枢纽（物流园区）",
                },
                {
                    value: "集散通用型货运枢纽（物流园区）",
                    label: "集散通用型货运枢纽（物流园区）",
                },
                { value: "县（区）货运站场", label: "县（区）货运站场" },
            ],
            currentPage: 1, //当前页
            pageSize: 30, //每页记录数
            direction: "rtl",
            props: { multiple: true },
            regions: [
                {
                    value: "",
                    label: "黑龙江省",
                },
            ],
            formInline: {
                user: "",
                region: "",
            },
        };
    },
    mounted() {
        this.getRegion();
    },
    methods: {
        handleChange(value) {
            console.log(this.xmlx, 111111);
            console.log(value);
            console.log(this.postData());
        },
        clearCondition() {
            this.region = [""];
            this.xmmc = "";
            this.xmnd = [""];
            this.checkedJsxz = [];
            this.checkedXmlx = [];
            this.listtotalShow = false;
            this.listboxShow = false;
            this.contentShow = false;
            this.introShow = false;
            this.tableData = [];
            this.$emit("airportFilter", "1<>1");
        },
        getRegion() {
            var postData = { SSDS: "" };
            this.http
                .post("/api/Plan_airport/getRegion", postData)
                .then((res) => {
                    //遍历地市
                    res.forEach((v) => {
                        var item = {};
                        for (var i = 0; i < v.length; i++) {
                            var qx = v[i];
                            item.value = qx.szds;
                            item.label = qx.szds;
                        }
                        this.regions.push(item);
                    });
                });
        },
        search() {
            console.log(123123);
            // this.contentShow = false; //关闭详情页面
            // this.introShow = false; //关闭简介页面
            // this.listtotalShow = false; //关闭汇总显示
            this.listboxShow = true; //显示详情列表
            // console.log(this.postData());

            // this.airportFilter();
            // this.$emit("search");

            // this.getPageTotal(); //获取汇总
            var url = "/api/Plan_freight/GetPageData";
            var url2 = "/api/Plan_freight/GetPageTotal";
            if (this.xmlx[0] == "客运枢纽") {
                url = "/api/Plan_passenger/GetPageData";
                url2 = "/api/Plan_passenger/GetPageTotal";
            }
            if (this.xmlx[0] == "乡村运输服务站") {
                url = "/api/plan_township_transportation/GetPageData";
                url2 = "/api/plan_township_transportation/GetPageTotal";
            }
            console.log(this.xmlx);
            this.http
                .post(url, this.postData(), "正在请求数据，请稍候...")
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;
                        if (this.tableData.length > 0) {
                            var xhArray = [];
                            this.tableData.forEach((element) => {
                                if (element.XH != null) {
                                    xhArray.push(element.XH);
                                }
                            });
                            //地图渲染
                            this.findHinges(xhArray);
                        }

                        // console.log(res);
                    }
                });
            this.http
                .post(url2, this.postData(), "正在请求数据，请稍候...")
                .then((res) => {
                    this.total1 = res.zdmj || res.glkyzdmj;
                    this.total2 = res.jzmj || res.glkyjzmj;
                    this.total3 = res.ztz;
                    console.log(res, "0000000");
                });
        },
        airportFilter() {
            this.http
                .post("/api/Plan_airport/getMc", this.postData())
                .then((res) => {
                    var filter = "1<>1"; //设置默认条件，无结果时不渲染
                    if (res.length > 0) {
                        var str = "";
                        res.forEach((v) => {
                            str += "'" + v.mc + "',";
                        });

                        str = str.substr(0, str.length - 1);
                        filter = "name IN (" + str + ")";
                    }
                    this.$emit("airportFilter", filter);
                });
        },
        //汇总
        getPageTotal() {
            this.http
                .post("/api/Plan_airport/GetPageTotal", this.postData())
                .then((res) => {
                    console.log(res);
                    this.sswtz = res.sswtz;
                });
        },
        postData() {
            var ssds = "";
            if (this.region.length > 0) {
                ssds = this.region[0];
            }

            var nn = "XMMC";
            if (this.xmlx[0] == "客运枢纽") {
                nn == "ZHKYSNMC";
            }
            if (this.xmlx[0] == "乡村运输服务站") {
                nn == "KYZMC";
            }
            //项目名称
            var query_xmmc = {
                Name: nn,
                Value: this.xmmc,
                DisplayType: "like",
            };
            //所属地市
            var query_ssds = {
                Name: "SZDS",
                Value: ssds,
                DisplayType: "Equal",
            };
            //项目年度
            //   var query_xmnd = {
            //     Name: "XMND",
            //     Value: this.xmnd[0],
            //     DisplayType: "Equal",
            //   };

            //建设性质
            //   var query_jsxz = {
            //     Name: "JSXZ",
            //     Value: this.checkedJsxz.join(","),
            //     DisplayType: "checkbox",
            //   };

            //项目类型
            var query_xmfl = {
                Name: "FL",
                Value: this.checkedXmfl.join(","),
                DisplayType: "checkbox",
            };

            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: "XH",
                Order: "asc",
                wheres: JSON.stringify([
                    query_xmmc,
                    query_ssds,
                    //   query_xmnd,
                    //   query_jsxz,
                    query_xmfl,
                ]),
            };
            console.log(this.checkedXmfl);
            return postData;
        },
        //地图定位，调用父组件方法
        findPorject(xmmc) {
            this.$emit("findPorject", xmmc);
        },
        findHinges(xhArray) {
            this.$emit("findHinges", xhArray);
        },
        showInfo(item) {
            console.log(item);
            this.findPorject(item.XMMC); //地图定位
            this.roadModel = item;
            this.contentShow = true;
            this.contentChildShow = true;
            if (item.XMJJ != null) {
                this.introShow = true;
                this.introChildShow = false;
            } else {
                this.introShow = false;
            }
        },
        valueChange(value) {
            console.log(value);
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
};
</script>
<style scoped>
</style>