<template>
  <div>
    <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline map-form z-indextop"
    >
      <el-form-item class="map-search">
        <div class="map-search-content">
          <el-link
            class="sbtn-delete"
            icon="el-icon-delete"
            @click="
              currentPage = 1;
              clearCondition();
            "
            :underline="false"
            size="medium"
          ></el-link>
          <el-input
            size="medium"
            v-model="xmmc"
            clearable
            placeholder="请输入项目名称"
          ></el-input>
          <el-divider direction="vertical"></el-divider>
        </div>
        <el-button
          icon="el-icon-search"
          type="primary"
          size="medium"
          @click="search"
          class="btn-search"
          >搜索
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="region"
          :options="regions"
          placeholder="行政区划"
          :props="{ expandTrigger: 'hover' }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
      <!-- <el-form-item>
        <el-cascader
          size="medium"
          v-model="xmnd"
          :options="xmndList"
          placeholder="项目年度"
          :props="{ expandTrigger: 'hover' }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item> -->
      <!-- <el-form-item>
        <el-cascader
          size="medium"
          v-model="qqgzjz"
          placeholder="前期工作进展"
          :options="qqgzjzs"
          :props="{ expandTrigger: 'hover', multiple: true }"
          collapse-tags
          clearable
          @change="valueChange"
        ></el-cascader>
      </el-form-item> -->
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="gllx"
          :options="gllxList"
          placeholder="建设类别"
          :props="{ expandTrigger: 'hover' }"
          collapse-tags
          clearable
          @change="handleChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="checkedXmxz"
          :options="xmxzList"
          placeholder="项目性质"
          :props="{ expandTrigger: 'hover', multiple: true }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="checkedJsxz"
          :options="jsxzList"
          placeholder="建设性质"
          :props="{ expandTrigger: 'hover', multiple: true }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="checkedXmlx"
          :options="xmlxList"
          placeholder="项目类型"
          :props="{ expandTrigger: 'hover', multiple: true }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-cascader
                size="medium"
                v-model="checkedXmgn"
                :options="xmgnList"
                placeholder="项目功能"
                :props="{ expandTrigger: 'hover', multiple: true }"
                collapse-tags
                clearable
                @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
    </el-form>

    <div class="left-box z-indextop">
      <div
        class="listtotal"
        v-show="listtotalShow"
        @click="
          listtotalShow = false;
          listboxShow = true;
        "
      >
        <el-link type="primary" :underline="false"
          >共找到 {{ total }} 个搜索结果</el-link
        >
      </div>
      <div class="box" style="padding-top: 10px;" v-show="listboxShow">
        <div style="
        background: #8c8585;
    border-radius: 50%;
    position: absolute;
    height: 16px;
    line-height: 18px;
    width: 16px;
    text-align: center;
    color: #fff;
    right: 0px;right: 5px;
    top: 5px;cursor: pointer;" @click="listboxShow = false;listtotalShow=true">
          <i class="el-icon-close"></i>
        </div>
        <div class="box-content">
          <el-row :gutter="10">
            <el-col :span="7">
              <div class="map-total-item">
                <h5>项目数量</h5>
                <b class="date-num">{{ total }}</b> <small>个</small>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="map-total-item">
                <h5>建设规模</h5>
                <b class="date-num">{{ jsgm }}</b> <small>公里</small>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="map-total-item">
                <h5>十四五投资</h5>
                <b class="date-num">{{ sswtz.toFixed(1) }}</b>
                <small>亿元</small>
              </div>
            </el-col>
          </el-row>
          <div class="list-item" id="list-item">
            <div class="item" v-for="(item, index) in tableData" :key="index">
              <h3>
                <a
                  name="road_item"
                  href="javascript:void(0);"
                  @click="showInfo($event.target, item)"
                  >{{ item.XMMC }}</a
                >
              </h3>
              <el-row>
                <el-col :span="12">建设类别：{{ item.GLLX }}</el-col>
                <el-col :span="12"
                  >“十四五”投资(亿元)：{{ item.SSWZTZ }}</el-col
                >
              </el-row>
              <!-- <el-row>
                <el-col :span="24">
                  <el-link @click="updQqgzjz(item)">
                    <el-tag
                      type="info"
                      v-if="item.QQGZJZ == '未开展前期工作'"
                      size="mini"
                    >
                      {{ item.QQGZJZ }}
                    </el-tag>
                    <el-tag
                      type="success"
                      v-else-if="item.QQGZJZ == '前期工作已完成'"
                      size="mini"
                    >
                      {{ item.QQGZJZ }}
                    </el-tag>
                    <el-tag v-else size="mini">
                      {{ item.QQGZJZ }}
                    </el-tag>
                  </el-link>
                  <el-link
                    class="jianjie"
                    v-if="item.XMJJ != null"
                    @click="showIntro(item)"
                  >
                    <el-tag type="info" size="mini"> 简介 </el-tag>
                  </el-link>
                </el-col>
              </el-row> -->
              <!-- <el-link type="primary" class="xianzhuang" v-if="!item.XH == ''" icon="el-icon-location-information" @click="findRoad(item.XH)">现状</el-link> -->
              <el-link
                      type="primary"
                      class="dingwei"
                      v-if="item.XH != null && item.XH != ''"
                      icon="el-icon-location-information"
                      @click="findPorject(item)"
              >定位</el-link>
            </div>
          </div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :pager-count="5"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="right-box map-box">
      <div class="road-info z-indextop">
        <div class="box" v-show="roadShow">
          <div class="box-title1">
            <h3 class="tit">公路现状</h3>
            <span class="fright">
              <a
                href="javasciprt:void(0);"
                v-show="!roadChildShow"
                @click="roadChildShow = true"
                class="btn-down"
                ><i class="el-icon-arrow-up"></i></a
              ><a
                href="javasciprt:void(0);"
                class="btn-up"
                v-show="roadChildShow"
                @click="roadChildShow = false"
                style="display: none"
                ><i class="el-icon-arrow-down"></i
              ></a>
              <small> | </small>
              <a
                href="javasciprt:void(0);"
                class="btn-close"
                @click="roadShow = false"
                ><i class="el-icon-close"></i
              ></a>
            </span>
          </div>
          <div class="box-content" v-if="roadChildShow">
            <el-table
              :data="roadtableData"
              style="width: 100%"
              size="small "
              height="240"
              border
            >
              <el-table-column prop="lxbm" label="路线编码" :width="180">
              </el-table-column>
              <el-table-column
                prop="lxmc"
                show-overflow-tooltip
                label="路线名称"
                :width="180"
              >
              </el-table-column>
              <el-table-column prop="qdzh" label="起点桩号"> </el-table-column>
              <el-table-column prop="zdzh" label="止点桩号"> </el-table-column>
              <el-table-column prop="ldlc" label="路段里程"> </el-table-column>
              <el-table-column prop="jsdj" label="技术等级"> </el-table-column>
              <el-table-column prop="lmlx" label="路面类型"> </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div> -->
    <div class="right-box map-box">
      <div class="map-info z-indextop">
        <div class="box" v-show="contentShow">
          <div class="box-title1">
            <h3 class="tit">项目详情</h3>
            <span class="fright"
              ><a
                href="javasciprt:void(0);"
                class="btn-down"
                v-show="!contentChildShow"
                @click="
                  contentChildShow = true;
                  introChildShow = false;
                "
                style="display: none"
                ><i class="el-icon-arrow-down"></i
              ></a>
              <a
                href="javasciprt:void(0);"
                v-show="contentChildShow"
                @click="contentChildShow = false"
                class="btn-up"
                ><i class="el-icon-arrow-up"></i></a
              ><small> | </small
              ><a
                href="javasciprt:void(0);"
                class="btn-close"
                @click="contentShow = false"
                ><i class="el-icon-close"></i></a
            ></span>
          </div>
          <div class="box-content box-content-sm" v-if="contentChildShow">
            <Xmxq :roadModel="roadModel" />
          </div>
        </div>
        <div class="box" v-show="introShow">
          <div class="box-title1">
            <h3 class="tit">项目简介</h3>
            <span class="fright"
              ><a
                href="javasciprt:void(0);"
                class="btn-down"
                v-show="!introChildShow"
                @click="
                  introChildShow = true;
                  contentChildShow = false;
                "
                style="display: none"
                ><i class="el-icon-arrow-down"></i
              ></a>
              <a
                href="javasciprt:void(0);"
                class="btn-up"
                v-show="introChildShow"
                @click="introChildShow = false"
                ><i class="el-icon-arrow-up"></i></a
              ><small> | </small
              ><a
                href="javasciprt:void(0);"
                class="btn-close"
                @click="introShow = false"
                ><i class="el-icon-close"></i></a
            ></span>
          </div>
          <div
            class="box-content news"
            v-if="introChildShow"
            v-html="roadModel.XMJJ"
          ></div>
        </div>
        <div class="box" v-show="roadShow">
          <div class="box-title1">
            <h3 class="tit">公路详情</h3>
            <span class="fright"
              ><a
                href="javasciprt:void(0);"
                class="btn-down"
                v-show="!roadChildShow"
                @click="roadChildShow = true"
                style="display: none"
                ><i class="el-icon-arrow-down"></i
              ></a>
              <a
                href="javasciprt:void(0);"
                v-show="roadChildShow"
                @click="roadChildShow = false"
                class="btn-up"
                ><i class="el-icon-arrow-up"></i></a
              ><small> | </small
              ><a
                href="javasciprt:void(0);"
                class="btn-close"
                @click="roadShow = false"
                ><i class="el-icon-close"></i></a
            ></span>
          </div>
          <div class="box-content box-content-sm" v-if="roadChildShow">
            <Glxq :roadModel="roadModel" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="前期工作进展状态更新"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      width="35%"
    >
      <Qqgzjz :model="roadModel" v-on:qqgzjz_change="qqgzjz_change" />
    </el-dialog>
  </div>
</template>
<script>
import Qqgzjz from "@/components/Query/Road/Qqgzjz.vue";
import Xmxq from "@/components/Query/Road/Xmxq.vue";
import Glxq from "@/components/Query/Road/Glxq.vue";
export default {
  components: {
    Qqgzjz,
    Xmxq,
    Glxq,
  },
  data() {
    return {
      /**查询条件 begin*/
      xmmc: "", //项目名称
      xmnd: "", //项目年度
      gllx: "", //建设类别
      checkedXmxz: [], //项目性质
      checkedJsxz: [], //建设性质
      checkedXmlx: [], //项目类型
      checkedXmgn: [], //项目类型
      region: [""], //已选中行政区划
      qqgzjz: [], //前期工作进展
      /**查询条件 end*/

      dialogFormVisible: false,

      mapform: false, //筛选条件隐藏
      xmxzShow: false, //项目性质查询条件显隐
      jsxzShow: true, //建设性质查询条件显隐
      xmlxShow: false, //项目类型查询条件显隐

      listboxShow: false, //搜索结果隐藏
      listtotalShow: false, //搜索结果条数隐藏

      total: 0, //请求记录数
      jsgm: 0,
      jsqmqs: 0,
      ztz: 0,
      sswtz: 0,
      tableData: [],
      roadtableData: [],
      resolution: 0,
      map: null,
      roadModel: null,
      contentShow: false, //项目详情显示
      contentChildShow: false, //项目详情最小化切换
      introShow: false, //项目简介显示
      introChildShow: false, //项目简介最小化切换
      roadShow: false, //公路现状显示
      roadChildShow: false, //公路现状最小化切换
      activeIndex: "1",
      input: "",
      checkList: [],
      checkAll: false,
      xmxzList: [
        { value: "十三五续建", label: "十三五续建", disabled: false },
        {
          value: "十四五新开工项目",
          label: "十四五新开工项目",
          disabled: false,
        },
        {
          value: "十四五备选项目",
          label: "十四五备选项目",
          disabled: false,
        },
        { value: "十四五其他", label: "十四五其他", disabled: false },
        { value: "十四五谋划", label: "十四五谋划", disabled: false },
        { value: "谋划", label: "谋划", disabled: false },

        {
          value: "2021年新开工",
          label: "2021年新开工",
          disabled: false,
        },

        {
          value: "2021年已安排计划",
          label: "2021年已安排计划",
          disabled: false,
        },
        { value: "2022年建设", label: "2022年建设", disabled: false },
        { value: "2023年建设", label: "2023年建设", disabled: false },
        {
          value: "2024-2025年建设",
          label: "2024-2025年建设",
          disabled: false,
        },
      ],
      jsxzList: [
        { value: "新建", label: "新建", disabled: false },
        { value: "升级改造", label: "升级改造", disabled: false },
        { value: "原级改造", label: "原级改造", disabled: false },
        { value: "路面改造", label: "路面改造", disabled: false },
        { value: "改扩建", label: "改扩建", disabled: false },
      ],
      xmlxList: [
        { value: "正选项目", label: "正选项目", disabled: false },
        { value: "备选项目", label: "备选项目", disabled: false },
      ],
      xmgnList: [
        { value: "出省通道", label: "出省通道", disabled: false },
        { value: "区域内连通农林场、乡镇、景区", label: "区域内连通农林场、乡镇、景区", disabled: false },
        { value: "城市过境段", label: "城市过境段", disabled: false },
        { value: "瓶颈路段", label: "瓶颈路段", disabled: false },
        { value: "砂石路", label: "砂石路", disabled: false },
        { value: "跨区域通道", label: "跨区域通道", disabled: false },
        { value: "重要节点连接国省干线", label: "重要节点连接国省干线", disabled: false },
      ],
      // xmxzList: [
      //     { value: "十三五续建项目", label: "“十三五”续建项目" },
      //     { value: "十四五新开工项目", label: "“十四五”新开工项目" },
      //     { value: "十四五谋划项目", label: "“十四五”谋划项目" },
      // ],
      // jsxzList: [
      //     { value: "升级改造", label: "升级改造" },
      //     { value: "原级改造", label: " 原级改造" },
      //     { value: "路面改造", label: " 路面改造" },
      //     { value: "新建", label: " 新建 " },
      //     { value: "扩容改造", label: "扩容改造" },
      // ],
      // xmlxList: [
      //     { value: "十三五续建项目", label: "十三五续建" },
      //     { value: "已启动前期项目", label: "已启动前期" },
      //     { value: "出省通道项目", label: "出省通道" },
      //     {
      //         value: "地市正式行文、建设积极性较高项目",
      //         label: "地市正式行文",
      //     },
      //     {
      //         value: "重要节点连接国省干线项目",
      //         label: "重要节点连接国省干线",
      //     },
      //     {
      //         value: "砂石路项目（地市未报）",
      //         label: "砂石路（地市未报）",
      //     },
      //     { value: "瓶颈路段项目", label: "瓶颈路段" },
      //     { value: "省道未列养项目", label: "省道未列养" },
      // ],
      currentPage: 1, //当前页
      pageSize: 30, //每页记录数
      direction: "rtl",
      props: { multiple: true },
      regions: [
        {
          value: "",
          label: "黑龙江省",
        },
      ],
      qqgzjzs: [
        {
          value: "未开展前期工作",
          label: "未开展前期工作",
        },
        {
          value: "前期工作进行中",
          label: "前期工作进行中",
          children: [
            {
              value: "工可研-报告编制中",
              label: "工可研-报告编制中",
            },
            {
              value: "工可研-报告编制已完成",
              label: "工可研-报告编制已完成",
            },
            {
              value: "工可研-已评审待批复",
              label: "工可研-已评审待批复",
            },
            {
              value: "工可研-已批复",
              label: "工可研-已批复",
            },
            {
              value: "初步设计-编制中",
              label: "初步设计-编制中",
            },
            {
              value: "初步设计-评审待批复",
              label: "初步设计-评审待批复",
            },
            {
              value: "初步设计-已批复",
              label: "初步设计-已批复",
            },
          ],
        },
        {
          value: "前期工作已完成",
          label: "前期工作已完成",
        },
      ],
      xmndList: [
        //规划年度
        {
          value: "",
          label: "“十四五”投资",
        },
        {
          value: "2021",
          label: "2021年度计划",
        },
      ],
      gllxList: [
        //建设类别
        {
          value: "",
          label: "全部",
        },
        {
          value: "高速公路",
          label: "高速公路",
        },
        {
          value: "普通国道",
          label: "普通国道",
        },
        {
          value: "普通省道",
          label: "普通省道",
        },
        // {
        //   value: "G331",
        //   label: "G331丹阿公路",
        // },
      ],
      formInline: {
        user: "",
        region: "",
      },
    };
  },
  mounted() {
    this.getRegion();
  },
  methods: {
    qqgzjz_change() {
      this.dialogFormVisible = false;
      this.search();
    },
    clearCondition() {
      this.region = [""];
      this.xmmc = "";
      this.xmnd = [""];
      this.qqgzjz = [];
      this.gllx = [""];
      this.checkedXmxz = [];
      this.checkedJsxz = [];
      this.checkedXmlx = [];
      this.listtotalShow = false;
      this.listboxShow = false;
      this.contentShow = false;
      this.roadShow = false;
      this.introShow = false;
      this.tableData = [];
      this.$emit("planFilter", "1<>1", "1<>1");
    },
    getRegion() {
      var postData = { SSDS: "", SSX: "" };
      this.http.post("/api/Plan_road/getRegion", postData).then((res) => {
        //遍历地市
        res.forEach((v) => {
          var item = {};
          var qxList = [];
          //遍历区县
          for (var i = 0; i < v.length; i++) {
            ``;
            var qx = v[i];
            //设置地市名称
            if (i == 0) {
              item.value = qx.ssds;
              item.label = qx.ssds;
              qxList.push({ value: "", label: "全部" });
            }

            qxList.push({ value: qx.ssx, label: qx.ssx });
            item.children = qxList;
          }
          this.regions.push(item);
        });
      });
    },
    search() {
      this.currentPage = 1;
      this.page_search();
      this.planFilter();
      this.$emit("search");
    },
    page_search() {
      document.getElementById("list-item").scrollTop = 0; //滚动条回顶部
      this.contentShow = false; //关闭详情页面
      this.introShow = false; //关闭简介页面
      this.listtotalShow = false; //关闭汇总显示
      this.listboxShow = true; //显示详情列表
      this.getPageTotal(); //获取汇总
      this.http
        .post(
          "/api/Plan_road/GetPageData",
          this.postData(),
          "正在加载，请稍候..."
        )
        .then((res) => {
          console.log(res);
          if (res.status == 0) {
            this.tableData = res.rows;
            this.total = res.total;
          }
        });
    },
    //汇总
    getPageTotal() {
      this.http
        .post("/api/Plan_road/GetPageTotal", this.postData())
        .then((res) => {
          console.log(res);
          this.jsgm = res.jsgm;
          this.jsqmqs = res.jsqmqs;
          this.ztz = res.ztz;
          this.sswtz = res.sswtz;
        });
    },
    postData() {
      var ssds = "";
      var ssx = "";
      if (this.region.length > 0) {
        ssds = this.region[0];
        if (this.region.length > 1) {
          ssx = this.region[1];
        }
      }

      //项目名称
      var query_xmmc = {
        Name: "XMMC",
        Value: this.xmmc,
        DisplayType: "like",
      };
      //所属地市
      var query_ssds = {
        Name: "SSDS",
        Value: ssds,
        DisplayType: "Equal",
      };

      //所属县
      var query_ssx = {
        Name: "SSX",
        Value: ssx,
        DisplayType: "Equal",
      };

      //项目年度
      var query_xmnd = {
        Name: "XMND",
        Value: this.xmnd[0],
        DisplayType: "Equal",
      };

      //前期工作进展
      var qqgzjzArray = [];
      this.qqgzjz.forEach((element) => {
        if (element.length > 0) {
          qqgzjzArray.push(element[0]);
          //当前期工作为进行中时，包括两个状态，用|分隔，保存时同样采用此方式
          if (element.length > 1) {
            qqgzjzArray.push(element[0] + "|" + element[1]);
          }
        }
      });
      var query_qqgzjz = {
        Name: "QQGZJZ",
        Value: qqgzjzArray.join(","),
        DisplayType: "checkbox",
      };

      //建设类别
      var query_gllx = {
        Name: "GLLX",
        Value: this.gllx[0],
        DisplayType: "Equal",
      };

      //G331特殊处理
      if (this.gllx == "G331") {
        query_gllx = {
          Name: "LXBH",
          Value: this.gllx[0],
          DisplayType: "Equal",
        };
      }

      //项目性质
      var query_xmxz = {
        Name: "XMXZ",
        Value: this.checkedXmxz.join(","),
        DisplayType: "checkbox",
      };

      //建设性质
      var query_jsxz = {
        Name: "JSXZ",
        Value: this.checkedJsxz.join(","),
        DisplayType: "checkbox",
      };

      //项目类型
      var query_xmlx = {
        Name: "XMLX",
        Value: this.checkedXmlx.join(","),
        DisplayType: "checkbox",
      };
      var query_xmgn = {
        Name: "XMGN",
        Value: this.checkedXmgn.join(","),
        DisplayType: "checkbox",
      };

      var postData = {
        page: this.currentPage,
        rows: this.pageSize,
        Sort: "XH",
        Order: "asc",
        wheres: JSON.stringify([
          query_xmgn,
          query_xmmc,
          query_ssds,
          query_ssx,
          query_xmnd,
          query_qqgzjz,
          query_gllx,
          query_xmxz,
          query_jsxz,
          query_xmlx,
        ]),
      };
      return postData;
    },

    planFilter() {
      this.http.post("/api/Plan_road/getXh", this.postData()).then((res) => {
        if (res.length == 0) {
          return false;
        }
        console.log("res", res);
        var highFilter = "1<>1"; //设置默认条件，无结果时不渲染
        var planFilter = "1<>1"; //设置默认条件，无结果时不渲染
        var highstr = "";
        var planstr = "";

        if (res.high.length > 0) {
          res.high.forEach((v) => {
            highstr += "'" + v.xh + "',";
          });
          highstr = highstr.substr(0, highstr.length - 1);
          highFilter = "XH IN (" + highstr + ")";
        }

        if (res.plan.length > 0) {
          res.plan.forEach((v) => {
            planstr += v.objectid + ",";
          });

          planstr = planstr.substr(0, planstr.length - 1);
          planFilter = "OBJECTID IN (" + planstr + ")";
        }
        // console.log(planFilter,highFilter);
  // console.log(123123);
        this.$emit("planFilter", highFilter, planFilter);
      });
    },

    //地图定位，调用父组件方法
    findPorject(item) {
      this.$emit("findPorject", item);
    },
    updQqgzjz(item) {
      console.log(item);
      this.roadModel = item;
      this.dialogFormVisible = true;
    },
    //显示项目详情
    showInfo(value, item) {
      //清空选中样式
      var nodeList = document.getElementsByName("road_item");
      nodeList.forEach((element) => {
        element.style = "";
      });

      value.style = "color:#008fd4"; //设置此条记录选中样式
      this.roadModel = item;
      this.contentShow = true;
      this.contentChildShow = true;

      this.roadShow = false;
      this.roadChildShow = false;

      this.introShow = false;

      //地图定位
      if (item.XH != null) {
        this.findPorject(item);
      }
    },
    //显示项目详情
    findPlanRoadInfo(xm, type) {
      console.log(xm);
      console.log(type);
      var that = this;
      var url;
      if (type == "plan") {
        url = "/api/Plan_road/getItemByXmmc?xmmc=" + xm;
      } else {
        url = "/api/Plan_road/getItem?xh=" + xm;
      }
      this.http.post(url).then((res) => {
        if (res == null) {
          return false;
        }
        console.log(res);
        var json = JSON.parse(res);
        that.roadModel = json;
        that.contentShow = true;
        that.contentChildShow = true;
        that.introShow = false;
        console.log("closeLoading");
      });
    },
    //查询公路现状
    findRoad(xh) {
      this.contentShow = false;
      this.introShow = false;

      this.$emit("findRoad", xh);

      this.http.showLoading("正在处理.....");
    },
    //绑定公路现状列表
    showRoadList(list) {
      console.log(list);
      this.roadtableData = list;
      this.roadShow = true;
      this.roadChildShow = true;
      this.http.closeLoading();
    },
    //显示项目简介
    showIntro(item) {
      this.roadModel = item;
      this.introShow = true;
      this.introChildShow = true;
      this.contentShow = false;
    },
    showRoad(features) {
      console.log(features);
      if (features.length > 0) {
        var feature = features[0];
        console.log("feature", feature);
        console.log("feature.values_", feature.values_);
        this.roadModel = feature.values_;
        this.introShow = false;
        this.contentShow = false;
        this.roadShow = true;
        this.roadChildShow = true;
      } else {
        this.roadShow = false;
      }
    },
    valueChange() {
      this.search();
    },
    handleChange(value) {
      this.checkedXmlx = [];
      this.checkedJsxz = [];
      this.checkedXmxz = [];
      if (value == "普通省道") {
        this.xmlxList.forEach((res) => {
          res.disabled = false;
        });
      } else {
        this.xmlxList.forEach((res) => {
          res.disabled = true;
        });
      }
      if (this.gllx == "高速公路") {
        this.jsxzList.forEach((res) => {
          res.disabled = true;
        });
      } else {
        this.jsxzList.forEach((res) => {
          res.disabled = false;
        });
      }

      var arr = [];
      if (this.gllx == "高速公路") {
        arr = ["十三五续建", "2021年新开工", "十四五其他", "十四五谋划"];
        this.xmxzList.forEach((res) => {
          if (arr.indexOf(res.value) >= 0) {
            res.disabled = false;
          } else {
            res.disabled = true;
          }
        });
      }
      if (this.gllx == "普通国道") {
        arr = ["十三五续建", "十四五新开工", "谋划", "十四五谋划"];
        this.xmxzList.forEach((res) => {
          if (arr.indexOf(res.value) >= 0) {
            res.disabled = false;
          } else {
            res.disabled = true;
          }
        });
      }
      if (this.gllx == "普通省道") {
        arr = [
          "十三五续建",
          "十四五新开工项目",
          "十四五备选项目",
        ];
        this.xmxzList.forEach((res) => {
          if (arr.indexOf(res.value) >= 0) {
            res.disabled = false;
          } else {
            res.disabled = true;
          }
        });
      }
      // if (value == "高速公路" || value == "普通国道" || value == "G331") {
      //     this.xmxzShow = true;
      //     this.xmlxShow = false;
      // }
      // if (value == "普通省道") {
      //     this.xmxzShow = false;
      //     this.xmlxShow = true;
      // }
      // if (value == "") {
      //     this.xmxzShow = false;
      //     this.xmlxShow = false;
      // }

      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page_search();
    },
    tranXmxz(xmxz) {
      switch (xmxz) {
        case "“十三五”续建项目":
          return "十三五续建项目";
        case "“十四五”新开项目":
          return "十四五新开工项目";
        case "“十四五”谋划项目":
          return "十四五谋划项目";
      }
    },
    tranXmlx(xmlx) {
      switch (xmlx) {
        case "十三五续建":
          return "十三五续建项目";
        case "已启动前期":
          return "已启动前期项目";
        case "出省通道":
          return "出省通道项目";
        case "地市正式行文":
          return "地市正式行文、建设积极性较高项目";
        case "重要节点连接国省干线 ":
          return "重要节点连接国省干线项目";
        case "砂石路（地市未报）":
          return "砂石路项目（地市未报）";
        case "瓶颈路段":
          return "瓶颈路段项目";
        case "省道未列养":
          return "省道未列养项目";
      }
    },
  },
};
</script>
<style scoped>
</style>
