<template>
  <div>
    <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline map-form z-indextop"
    >
      <el-form-item class="map-search">
        <div class="map-search-content">
          <el-link
            class="sbtn-delete"
            icon="el-icon-delete"
            @click="
              currentPage = 1;
              clearCondition();
            "
            :underline="false"
            size="medium"
          ></el-link>
          <el-input
            size="medium"
            v-model="xmmc"
            clearable
            placeholder="请输入项目名称"
          ></el-input
          ><el-divider direction="vertical"></el-divider>
        </div>
        <el-button
          icon="el-icon-search"
          type="primary"
          size="medium"
          @click="
            currentPage = 1;
            search();
          "
          class="btn-search"
          >搜索
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="region"
          :options="regions"
          placeholder="行政区划"
          :props="{ expandTrigger: 'hover' }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="xmnd"
          :options="xmndList"
          placeholder="项目年度"
          :props="{ expandTrigger: 'hover' }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="checkedJsxz"
          :options="jsxzList"
          placeholder="建设性质"
          :props="{ expandTrigger: 'hover', multiple: true }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-cascader
          size="medium"
          v-model="checkedXmlx"
          :options="xmlxList"
          placeholder="项目类型"
          :props="{ expandTrigger: 'hover', multiple: true }"
          collapse-tags
          clearable
          @change="valueChange"
        >
        </el-cascader>
      </el-form-item>
    </el-form>
    <div class="left-box z-indextop">
      <div
        class="listtotal"
        v-show="listtotalShow"
        @click="
          listtotalShow = false;
          listboxShow = true;
        "
      >
        <el-link type="primary" :underline="false"
          >共找到 {{ total }} 个搜索结果</el-link
        >
      </div>
      <div class="box" v-show="listboxShow">
        <div class="box-content">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="map-total-item">
                <h5>项目数量</h5>
                <b class="date-num">{{ total }}</b> <small>个</small>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="map-total-item">
                <h5>十四五投资</h5>
                <b class="date-num">{{ sswtz.toFixed(1) }}</b>
                <small>亿元</small>
              </div>
            </el-col>
          </el-row>
          <div class="list-item">
            <div class="item" v-for="(item, index) in tableData" :key="index">
              <h3>
                <el-tag class="fright tit-tag" effect="plain" size="mini">{{
                  item.XMLX
                }}</el-tag>
                <a href="javascript:void(0);" @click="showInfo(item)">{{
                  item.XMMC
                }}</a>
              </h3>
              <el-row>
                <el-col :span="12">建设性质：{{ item.JSXZ }}</el-col
                ><el-col :span="12"
                  >“十四五”投资(亿元)：{{ item.SSWTZ }}</el-col
                ></el-row
              >
              <el-row>
                <el-col :span="12">所在地市：{{ item.SZDS }}</el-col>
              </el-row>
              <el-link
                type="primary"
                class="dingwei"
                v-if="!item.XH == ''"
                icon="el-icon-location-information"
                @click="findPorject(item.XMMC)"
                >定位</el-link
              >
            </div>
          </div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="right-box map-box">
      <div class="map-info z-indextop">
        <div class="box" v-show="contentShow">
          <div class="box-title1">
            <h3 class="tit">项目详情</h3>
            <span class="fright"
              ><a
                href="javasciprt:void(0);"
                class="btn-down"
                v-show="!contentChildShow"
                @click="
                  contentChildShow = true;
                  introChildShow = false;
                "
                style="display: none"
                ><i class="el-icon-arrow-down"></i
              ></a>
              <a
                href="javasciprt:void(0);"
                v-show="contentChildShow"
                @click="contentChildShow = false"
                class="btn-up"
                ><i class="el-icon-arrow-up"></i></a
              ><small> | </small
              ><a
                href="javasciprt:void(0);"
                class="btn-close"
                @click="contentShow = false"
                ><i class="el-icon-close"></i></a
            ></span>
          </div>
          <div class="box-content box-content-sm" v-if="contentChildShow">
            <el-descriptions :column="1">
              <el-descriptions-item label="项目名称">
                {{ roadModel.XMMC }}
              </el-descriptions-item>
              <el-descriptions-item label="所在地市">
                {{ roadModel.SZDS }}
              </el-descriptions-item>
              <el-descriptions-item label="十四五投资"
                ><b> {{ roadModel.SSWTZ }}</b> 亿元
              </el-descriptions-item>
              <el-descriptions-item label="项目类型">
                {{ roadModel.XMLX }}
              </el-descriptions-item>
              <el-descriptions-item label="建设性质">
                {{ roadModel.JSXZ }}
              </el-descriptions-item>
              <el-descriptions-item label="建设内容">
                {{ roadModel.JSNR }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      /**查询条件 begin*/
      xmmc: "", //项目名称
      xmnd: "", //项目年度
      gllx: "", //建设类别
      checkedXmxz: [], //项目性质
      checkedJsxz: [], //建设性质
      checkedXmlx: [], //项目类型
      region: [""], //已选中行政区划
      qqgzjz: [], //前期工作进展
      /**查询条件 end*/

      listboxShow: false, //搜索结果隐藏
      listtotalShow: false, //搜索结果条数隐藏

      total: 0, //请求记录数
      jsgm: 0,
      jsqmqs: 0,
      ztz: 0,
      sswtz: 0,
      tableData: [],
      resolution: 0,
      map: null,
      roadModel: null,
      contentShow: false, //项目详情显示
      contentChildShow: false, //项目详情最小化切换
      activeIndex: "1",
      input: "",
      checkList: [],
      checkAll: false,

      jsxzList: [
        { value: "新建", label: " 新建 " },
        { value: "改扩建", label: "改扩建" },
      ],
      xmlxList: [
        { value: "枢纽机场", label: "枢纽机场" },
        { value: "通用机场", label: "通用机场" },
        { value: "支线机场", label: "支线机场" },
        { value: "民用运输机场", label: "民用运输机场" },
      ],
      currentPage: 1, //当前页
      pageSize: 30, //每页记录数
      direction: "rtl",
      props: { multiple: true },
      regions: [
        {
          value: "",
          label: "黑龙江省",
        },
      ],
      xmndList: [
        //规划年度
        {
          value: "",
          label: "“十四五”投资",
        },
        {
          value: "2021",
          label: "2021年度计划",
        },
      ],
      formInline: {
        user: "",
        region: "",
      },
    };
  },
  mounted() {
    this.getRegion();
    // this.search(); //查询数据
  },
  methods: {
    clearCondition() {
      this.region = [""];
      this.xmmc = "";
      this.xmnd = [""];
      this.checkedJsxz = [];
      this.checkedXmlx = [];
      this.listtotalShow = false;
      this.listboxShow = false;
      this.contentShow = false;
      this.introShow = false;
      this.tableData = [];
      this.$emit("airportFilter", "1<>1");
    },
    getRegion() {
      var postData = { SSDS: "" };
      this.http.post("/api/Plan_airport/getRegion", postData).then((res) => {
        //遍历地市
        res.forEach((v) => {
          var item = {};
          for (var i = 0; i < v.length; i++) {
            var qx = v[i];
            item.value = qx.szds;
            item.label = qx.szds;
          }
          this.regions.push(item);
        });
      });
    },
    search() {
      this.contentShow = false; //关闭详情页面
      this.introShow = false; //关闭简介页面
      this.listtotalShow = false; //关闭汇总显示
      this.listboxShow = true; //显示详情列表
      console.log(this.postData());

      this.airportFilter();
      this.$emit("search");

      this.getPageTotal(); //获取汇总
      this.http
        .post(
          "/api/Plan_airport/GetPageData",
          this.postData(),
          "正在加载，请稍候..."
        )
        .then((res) => {
          console.log(res);
          if (res.status == 0) {
            this.tableData = res.rows;
            this.total = res.total;
          }
        });
    },
    airportFilter() {
      this.http.post("/api/Plan_airport/getMc", this.postData()).then((res) => {
        var filter = "1<>1"; //设置默认条件，无结果时不渲染
        if (res.length > 0) {
          var str = "";
          res.forEach((v) => {
            str += "'" + v.mc + "',";
          });

          str = str.substr(0, str.length - 1);
          filter = "name IN (" + str + ")";
        }
        this.$emit("airportFilter", filter);
      });
    },
    //汇总
    getPageTotal() {
      this.http
        .post("/api/Plan_airport/GetPageTotal", this.postData())
        .then((res) => {
          console.log(res);
          this.sswtz = res.sswtz;
        });
    },
    postData() {
      var ssds = "";
      if (this.region.length > 0) {
        ssds = this.region[0];
      }

      //项目名称
      var query_xmmc = {
        Name: "XMMC",
        Value: this.xmmc,
        DisplayType: "like",
      };
      //所属地市
      var query_ssds = {
        Name: "SZDS",
        Value: ssds,
        DisplayType: "Equal",
      };
      //项目年度
      var query_xmnd = {
        Name: "XMND",
        Value: this.xmnd[0],
        DisplayType: "Equal",
      };

      //建设性质
      var query_jsxz = {
        Name: "JSXZ",
        Value: this.checkedJsxz.join(","),
        DisplayType: "checkbox",
      };

      //项目类型
      var query_xmlx = {
        Name: "XMLX",
        Value: this.checkedXmlx.join(","),
        DisplayType: "checkbox",
      };

      var postData = {
        page: this.currentPage,
        rows: this.pageSize,
        Sort: "XH",
        Order: "asc",
        wheres: JSON.stringify([
          query_xmmc,
          query_ssds,
          query_xmnd,
          query_jsxz,
          query_xmlx,
        ]),
      };
      return postData;
    },
    //地图定位，调用父组件方法
    findPorject(xmmc) {
      this.$emit("findPorject", xmmc);
    },
    showInfo(item) {
      this.findPorject(item.XMMC); //地图定位
      this.roadModel = item;
      this.contentShow = true;
      this.contentChildShow = true;
      if (item.XMJJ != null) {
        this.introShow = true;
        this.introChildShow = false;
      } else {
        this.introShow = false;
      }
    },
    valueChange(value) {
      console.log(value);
      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
  },
};
</script>
<style scoped>
</style>