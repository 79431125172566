<template>
  <div>
    <el-descriptions :column="1">
      <el-descriptions-item label="路线编码">
        {{ roadModel.LXBM }}
      </el-descriptions-item>
      <el-descriptions-item label="路线名称">
        {{ roadModel.LXMC }}
      </el-descriptions-item>
      <el-descriptions-item label="路段序列号">
        {{ roadModel.Ldxlh }}
      </el-descriptions-item>
      <el-descriptions-item label="起点桩号">
        {{ roadModel.Qdzh }}
      </el-descriptions-item>
      <el-descriptions-item label="止点桩号">
        {{ roadModel.Zdzh }}
      </el-descriptions-item>
      <el-descriptions-item label="路段技术等级">
        {{ roadModel.Ldjsdj }}
      </el-descriptions-item>
      <el-descriptions-item label="路段路面类型">
        {{ roadModel.Ldlmlx }}
      </el-descriptions-item>
      <el-descriptions-item label="路基宽度">
        {{ roadModel.LJKD }}
      </el-descriptions-item>
      <el-descriptions-item label="是否重复路段">
        {{ roadModel.Sfwcfld == "2" ? "否" : "是" }}
      </el-descriptions-item>
      <el-descriptions-item label="是否断头路">
        {{ roadModel.Sfdtl == "2" ? "否" : "是" }}
      </el-descriptions-item>
      <el-descriptions-item label="是否城管路">
        {{ roadModel.Sfwcgl == "2" ? "否" : "是" }}
      </el-descriptions-item>
      <el-descriptions-item label="行政区划代码">
        {{ roadModel.Xzqh }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
export default {
  props: ["roadModel"],
  mounted() {
    console.log("roadModel", this.roadModel);
  },
  activated() {
    console.log("roadModel", this.roadModel);
  },
};
</script>
