<template>
    <div id="map-container"></div>
</template>

<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import VectorSource from "ol/source/Vector";
import { Vector as VectorLayer } from "ol/layer";
// import VectorTileSource from "ol/source/VectorTile";
// import VectorTileLayer from "ol/layer/VectorTile";
// import MVT from "ol/format/MVT";
import { GeoJSON, WFS } from "ol/format";
import { getCenter } from "ol/extent";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import Draw from "ol/interaction/Draw";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import { unByKey } from "ol/Observable";
import Intersects from "ol/format/filter/Intersects";
import Overlay from "ol/Overlay";
import * as olSphere from "ol/sphere";

// import OverviewMap from "ol/control/OverviewMap";
import {
    // or as orFilter,
    // and as andFilter,
    // like as likeFilter,
    equalTo as equalToFilter,
} from "ol/format/filter";
import { vector_style, meatureStyle, hinge_style } from "../../js/map/style";
import {
    // gdLayer,
    bgLayer,
    backgroundLayer,
    // planLayer,
    // airportLayer,
    cityLayer,
    roadGsLabelLayer,
    roadGsSecondLabelLayer,
    roadGLabelLayer,
    roadSLabelLayer,
    // hingeLayer,
    roadLayer,
} from "../../js/map/layer";

import {
    baseUrl,
    wmsUrl,
    //  hingeUrl
} from "../../js/map/url";

export default {
    data() {
        return {
            extent: [11493032, 4984223, 16555439, 7427470],

            planLayer: null,
            planSource: null,

            highLayer: null,
            highSource: null,

            roadSource: null, //公路查询源

            airportLayer: null,
            airportSource: null,

            hingeLayer: null,
            hingeSource: null,

            drawSource: null,
            drawVector: null,

            vectorSource: null,
            vector: null,

            resolution: 0,
            map: null,
            helpTooltipElement: null,
            featureType: "LineString",
            measureTooltipElement: null,
            measureTooltip: null,
            draw: null,
            listener: null,
            drawingFeature: null,
            helpMsg: "点击继续绘制",
            lineHelpMsg: "点击继续绘制线",
            polygonHelpMsg: "点击继续绘制面",

            meatureState: false, //测量工具状态
        };
    },
    beforeDestroy() {
        console.log(this.map, "000000000000000000");
        this.map = null;
        console.log(this.map, "000000000000000000");
    },
    mounted() {
        // this.hingeLayerShow(true); //显示隐藏枢纽站场图层

        console.log("map.mounted");
        //地图渲染
        this.vectorSource = new VectorSource();
        this.vector = new VectorLayer({
            source: this.vectorSource,
            style: vector_style,
        });

        // this.findHinge(5); //根据序号查询枢纽站场

        //地图测量
        this.drawSource = new VectorSource();
        this.drawVector = new VectorLayer({
            source: this.drawSource,
            style: vector_style,
        });
    console.log(wmsUrl);
        this.planSource = new TileWMS({
            url: wmsUrl,
            params: {
                FORMAT: "image/png",
                VERSION: "1.1.1",
                tiled: true,
                STYLES: "",
                LAYERS: "cite:plan_road_project", //规划公路
                exceptions: "application/vnd.ogc.se_inimage",
            },
        });

        this.highSource = new TileWMS({
            url: wmsUrl,
            params: {
                FORMAT: "image/png",
                VERSION: "1.1.1",
                tiled: true,
                STYLES: "",
                LAYERS: "cite:plan_road", //规划高速
                exceptions: "application/vnd.ogc.se_inimage",
            },
        });

        this.roadSource = new TileWMS({
            url: wmsUrl,
            params: {
                FORMAT: "image/png",
                VERSION: "1.1.1",
                tiled: true,
                STYLES: "",
                LAYERS: "cite:road",
                exceptions: "application/vnd.ogc.se_inimage",
            },
        });

        this.airportSource = new TileWMS({
            url: wmsUrl,
            params: {
                FORMAT: "image/png",
                VERSION: "1.1.1",
                tiled: true,
                STYLES: "",
                LAYERS: "cite:airport",
                exceptions: "application/vnd.ogc.se_inimage",
            },
        });

        this.hingeSource = new VectorSource();
        this.hingeLayer = new VectorLayer({
            source: this.hingeSource,
            style: hinge_style,
        });

        // this.hingeSource = new VectorTileSource({
        //   format: new MVT(),
        //   url: hingeUrl,
        //   //cacheSize: 1
        // });

        // //枢纽
        // this.hingeLayer = new VectorTileLayer({
        //   source: this.hingeSource,
        //   style: hinge_style,
        //   visible: true,
        // });

        //规划公路图层
        this.planLayer = new TileLayer({
            source: this.planSource,
            visible: false,
        });

        //规划公路图层
        this.highLayer = new TileLayer({
            source: this.highSource,
            visible: true,
        });

        //规划机场图层
        this.airportLayer = new TileLayer({
            source: this.airportSource,
            visible: false,
        });

        this.map = new Map({
            controls: defaultControls({
                zoom: false,
                rotate: false,
                attribution: false,
            }).extend([
                this.scaleControl(),
                // new OverviewMap({
                //   collapsible: false,
                // }),
            ]),
            layers: [
                // gdLayer,
                bgLayer,
                // tunnelLayer,
                // bridgeLayer,
                roadLayer,
                backgroundLayer,
                this.airportLayer,
                cityLayer,
                roadGsLabelLayer,
                roadGsSecondLabelLayer,
                roadGLabelLayer,
                roadSLabelLayer,
                this.hingeLayer,
                this.planLayer,
                this.highLayer,
                this.vector,
                this.drawVector,
            ],
            target: "map-container",
            view: new View({
                center: [14198942, 6261109],
                zoom: 6,
                minZoom: 6,
                maxZoom: 19,
                // extent: this.extent,
            }),
        });
        var that = this;
        this.map.on("pointermove", function (evt) {
            if (evt.dragging) {
                return;
            }
            if (that.drawingFeature) {
                var geom = that.drawingFeature.getGeometry();
                if (geom instanceof Polygon) {
                    that.helpMsg = that.polygonHelpMsg;
                    // console.log("polygon");
                } else if (geom instanceof LineString) {
                    that.helpMsg = that.lineHelpMsg;
                    // console.log("linestring");
                }
            }
            if (that.helpTooltipElement) {
                that.helpTooltipElement.innerHTML = that.helpMsg;
                that.helpTooltip.setPosition(evt.coordinate);
                that.helpTooltipElement.classList.remove("hidden");
            }
        });
        that.map.getViewport().addEventListener("mouseout", () => {
            if (that.helpTooltipElement) {
                that.helpTooltipElement.classList.add("hidden");
            }
        });

        // this.map.on("click", function (evt) {
        //   console.log(evt);
        // });

        //点击查询
        this.map.on("singleclick", function (evt) {
            var pixel = that.map.getEventPixel(evt.originalEvent);
            var featureInfo = that.map.forEachFeatureAtPixel(
                pixel,
                function (feature, layer) {
                    return { feature: feature, layer: layer };
                }
            );
            if (
                featureInfo !== undefined &&
                featureInfo !== null &&
                featureInfo.layer !== null
            ) {
                // console.log("打印选择要素");
                // console.log(featureInfo.feature);
                // console.log("打印选择要素所属Layer");
                // console.log(featureInfo.layer);

                //拾取枢纽场站
                if (featureInfo.layer == that.hingeLayer) {
                    that.$emit("hingeclick", featureInfo.feature.values_);
                    return false;
                }
            }

            //当测量时
            if (that.meatureState) {
                return false;
            }
            that.clearGraphic();
            const viewResolution = /** @type {number} */ (
                that.map.getView().getResolution()
            );

            var sources = [that.highSource, that.planSource, that.roadSource];
            for (let index = 0; index < sources.length; index++) {
                const source = sources[index];
                const url = source.getFeatureInfoUrl(
                    evt.coordinate,
                    viewResolution,
                    "EPSG:900913",
                    {
                        INFO_FORMAT: "application/json",
                    }
                );
                if (url) {
                    console.log(url);
                    fetch(url)
                        .then((response) => response.text())
                        .then((json) => {
                            var features = new GeoJSON().readFeatures(json);
                            if (features.length > 0) {
                                that.vectorSource.addFeatures(features);
                                console.log(features[0],1000010000);
                                //规划高速
                                if (
                                    source == that.highSource &&
                                    that.highLayer.getVisible()
                                ) {
                                    var xh = features[0].values_.XH;
                                    that.$emit("singleclick", {
                                        xm: xh,
                                        type: "high",
                                    });
                                    return false;
                                }
                                //规划国省道
                                if (
                                    source == that.planSource &&
                                    that.planLayer.getVisible()
                                ) {
                                    var xmmc = features[0].values_.BZ;
                                    that.$emit("singleclick", {
                                        xm: xmmc,
                                        type: "plan",
                                    });
                                    return false;
                                }
                                //公路底图
                                if (source == that.roadSource) {
                                    that.$emit("roadclick", features);
                                    return false;
                                }
                            }
                        });
                }
            }
        });

        //地图拖拽事件
        this.map.on("pointerdrag", function (evt) {
            console.log("pointerdrag", evt);
            that.$emit("mapchange"); //传递地图变化
        });

        this.map.getView().on("change:resolution", function (evt) {
            console.log("change:resolution", evt);
            // that.resolution = evt.oldValue;
            // that.$emit("mapchange"); //传递地图变化
        });
        this.map.getView().on("change:center", function (evt) {
            console.log("change:center", evt);
            // c.$emit("mapchange"); //传递地图变化
        });

        // 因为是异步加载，所以要采用事件监听的方式来判定是否加载完成
        this.vector.getSource().on("change", function (evt) {
            var _source = evt.target;
            if (_source.getState() === "ready") {
                // 获取要素四至
                if (!that.vectorSource.isEmpty()) {
                    let extent = that.vectorSource.getExtent();
                    console.log(
                        "that.vectorSource",
                        that.vectorSource.getFeatures()
                    );
                    var features = that.vectorSource.getFeatures();
                    if (features.length == 0) {
                        return false;
                    }
                    let view = that.map.getView();
                    var feature = features[0];
                    console.log("feature.getGeometry()", feature.getGeometry());
                    if (feature.getGeometry().getType() == "Point") {
                        var point = feature.getGeometry();
                        view.setCenter(point.getCoordinates());
                        view.setZoom(14);
                    } else {
                        // let resolution = that.map
                        //   .getView()
                        //   .getResolutionForExtent(extent, that.map.getSize());
                        // view.setResolution(resolution);
                        // view.fit(extent, {
                        //   constrainResolution: false,
                        //   earest: true,
                        // });
                        view.fit(extent, { padding: [150, 150, 300, 300] });
                    }
                }
            }
        });
    },
    methods: {
        //枢纽站场显示隐藏
        hingeLayerShow(state) {
            this.hingeLayer.setVisible(state);
        },
        findHinge(xh) {
            var that = this;
            if (!this.vectorSource.isEmpty()) {
                this.vectorSource.clear();
            }

            var filter = equalToFilter("序号", xh);
            // generate a GetFeature request
            const featureRequest = new WFS().writeGetFeature({
                featureTypes: ["plan_hinge"],
                outputFormat: "application/json",
                filter: filter,
            });

            // then post the request and add the received features to a layer
            fetch(baseUrl + "wfs", {
                method: "POST",
                body: new XMLSerializer().serializeToString(featureRequest),
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (json) {
                    if (json.features.length == 0) {
                        console.log("查询无结果");
                        return false;
                    }
                    console.log(json);
                    const features = new GeoJSON().readFeatures(json);
                    that.vectorSource.addFeatures(features);
                    //   if (that.vectorSource.getFeatures().length > 0) {
                    //     console.log(that.vectorSource.getFeatures());
                    //   }
                });
        },
        findHinges(xhArray) {
            var that = this;
            if (!this.hingeSource.isEmpty()) {
                this.hingeSource.clear();
            }
            xhArray.forEach((xh) => {
                var filter = equalToFilter("序号", xh);
                // generate a GetFeature request
                const featureRequest = new WFS().writeGetFeature({
                    featureTypes: ["plan_hinge"],
                    outputFormat: "application/json",
                    filter: filter,
                });

                // then post the request and add the received features to a layer
                fetch(baseUrl + "wfs", {
                    method: "POST",
                    body: new XMLSerializer().serializeToString(featureRequest),
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (json) {
                        if (json.features.length == 0) {
                            console.log("查询无结果");
                            return false;
                        }
                        // console.log(json);
                        const features = new GeoJSON().readFeatures(json);
                        that.hingeSource.addFeatures(features);
                    });
            });
        },
        findRoad(xh) {
            var that = this;
            if (!this.vectorSource.isEmpty()) {
                this.vectorSource.clear();
            }

            var filter = equalToFilter("XMMC", xh);

            // generate a GetFeature request
            const featureRequest = new WFS().writeGetFeature({
                featureTypes: ["plan_road_project"],
                outputFormat: "application/json",
                filter: filter,
            });

            // then post the request and add the received features to a layer
            fetch(baseUrl + "wfs", {
                method: "POST",
                body: new XMLSerializer().serializeToString(featureRequest),
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (json) {
                    if (json.features.length == 0) {
                        console.log("查询无结果");
                        return false;
                    }
                    console.log(json);
                    const features = new GeoJSON().readFeatures(json);
                    that.vectorSource.addFeatures(features);
                    if (that.vectorSource.getFeatures().length > 0) {
                        that.queryRoad(that.vectorSource.getFeatures()[0]);
                    }
                });
        },
        //多边形查询,根据输入空间条件查询公路底图
        queryRoad(feature) {
            this.vectorSource.clear();
            var that = this;
            var featureRequest = new WFS().writeGetFeature({
                featureTypes: [
                    "Geo_Road_G",
                    "Geo_Road_S",
                    "Geo_Road_X",
                    "Geo_Road_Y",
                    "Geo_Road_Z",
                    "Geo_Road_C",
                ],
                outputFormat: "application/json",
                filter: new Intersects(
                    "the_geom",
                    feature.getGeometry(),
                    "EPSG:3857"
                ),
            });

            fetch(baseUrl + "wfs", {
                method: "POST",
                body: new XMLSerializer().serializeToString(featureRequest),
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (json) {
                    console.log("json", json);
                    var features = new GeoJSON().readFeatures(json);
                    if (features.length == 0) {
                        alert("没有数据");
                    } else {
                        console.log("features", features);
                        that.vectorSource.addFeatures(features);
                        // that.map.getView().fit(that.vectorSource.getExtent());
                        that.$emit("queryRoad", features);
                    }
                });
        },
        planFilter(highFilter, planFilter) {
            console.log(highFilter);
            console.log(planFilter);
            this.clearGraphic(); //清空渲染
            this.highSource.updateParams({
                // CQL_FILTER: "XMMC in ('铁科高速铁力至方正段','省道哈尔滨至北安公路道外段')",
                CQL_FILTER: highFilter,
            });
            this.planSource.updateParams({
                // CQL_FILTER: "XMMC in ('铁科高速铁力至方正段','省道哈尔滨至北安公路道外段')",
                CQL_FILTER: planFilter,
            });
        },
        airportFilter(filter) {
            console.log(filter);
            this.clearGraphic(); //清空渲染
            this.airportSource.updateParams({
                CQL_FILTER: filter,
            });
        },
        clearGraphic() {
            this.vectorSource.clear();
            this.drawSource.clear();
            this.map.removeInteraction(this.draw);
            this.map.getOverlays().clear();
        },
        //测量距离
        measureDistance(state) {
            this.meatureState = state;
            this.helpMsg = "点击继续绘制";
            this.clearGraphic();
            this.featureType = "LineString";
            if (state) {
                this.addInteraction();
            }
        },
        //测量面积
        measureArea(state) {
            this.meatureState = state;
            this.helpMsg = "点击继续绘制";
            this.clearGraphic();
            this.featureType = "Polygon";
            if (state) {
                this.addInteraction();
            }
        },
        createHelpTooltip() {
            if (this.helpTooltipElement) {
                this.helpTooltipElement.parentNode.removeChild(
                    this.helpTooltipElement
                );
            }
            this.helpTooltipElement = document.createElement("div");
            this.helpTooltipElement.className = "tooltip hidden";
            this.helpTooltip = new Overlay({
                element: this.helpTooltipElement,
                offset: [15, 0],
                positioning: "center-left",
            });
            this.map.addOverlay(this.helpTooltip);
        },

        // 格式化输出线的长度和多边形的面积
        formatLength(line) {
            var length = olSphere.getLength(line);
            var output;
            if (length > 100) {
                output = Math.round((length / 1000) * 100) / 100 + " km";
            } else {
                output = Math.round(length * 100) / 100 + " m";
            }
            return output;
        },
        formatArea(polygon) {
            var area = olSphere.getArea(polygon);
            var output;
            if (area > 10000) {
                output =
                    Math.round((area / 1000000) * 100) / 100 +
                    " km<sup>2</sup>";
            } else {
                output = Math.round(area * 100) / 100 + " m<sup>2</sup>";
            }
            return output;
        },
        createMeasureTooltip() {
            if (this.measureTooltipElement) {
                this.measureTooltipElement.parentNode.removeChild(
                    this.measureTooltipElement
                );
            }
            this.measureTooltipElement = document.createElement("div");
            this.measureTooltipElement.className = "tooltip tooltip-measure";
            this.measureTooltip = new Overlay({
                element: this.measureTooltipElement,
                offset: [0, -15],
                positioning: "bottom-center",
            });
            this.map.addOverlay(this.measureTooltip);
        },
        // 添加绘制控件
        addInteraction() {
            var type = this.featureType;
            this.draw = new Draw({
                source: this.drawSource,
                type: type,
                // 勾绘出要素的样式
                style: meatureStyle,
            });
            this.map.addInteraction(this.draw);

            try {
                this.createMeasureTooltip();
                this.createHelpTooltip();
            } catch (error) {
                console.log("error", error);
            }

            this.draw.on(
                "drawstart",
                (evt) => {
                    this.drawingFeature = evt.feature;
                    var tooltipCoord = evt.coordinate;
                    this.listener = this.drawingFeature
                        .getGeometry()
                        .on("change", (evt) => {
                            var geom = evt.target;
                            var output;
                            if (geom instanceof Polygon) {
                                output = this.formatArea(geom);
                                tooltipCoord = geom
                                    .getInteriorPoint()
                                    .getCoordinates();
                            } else if (geom instanceof LineString) {
                                output = this.formatLength(geom);
                                tooltipCoord = geom.getLastCoordinate();
                            }
                            this.measureTooltipElement.innerHTML = output;
                            this.measureTooltip.setPosition(tooltipCoord);
                        });
                },
                this
            );
            this.draw.on("drawend", (evt) => {
                console.log(evt);
                this.measureTooltipElement.className = "tooltip tooltip-static";
                this.measureTooltip.setOffset([0, -7]);
                this.drawingFeature = null;
                this.measureTooltipElement = null;
                this.createMeasureTooltip();
                unByKey(this.listener);
            });
        },
        bgLayerShow(state) {
            bgLayer.setVisible(state);
        },
        airportLayerShow(state) {
            this.airportFilter(null);
            this.airportLayerShow.setVisible(state);
        },
        backgroundLayerShow(state) {
            backgroundLayer.setVisible(state);
        },
        scaleControl() {
            var control = new ScaleLine({
                // units: "metric",
                units: "degrees",
                bar: true,
                steps: 4,
                text: true,
                minWidth: 140,
            });
            return control;
        },
        findPlanRoad(item) {
            var that = this;
            console.log(item,123);

            if (!this.vectorSource.isEmpty()) {
                this.vectorSource.clear();
            }

            var filter;
console.log(item.GLLX);
            var featureType;
            if (item.GLLX == "高速公路") {
                filter = equalToFilter("XH", item.XH);
                featureType = "plan_road";
            } else {
                filter = equalToFilter("BZ", item.XMMC);
                featureType = "plan_road_project";
            }
            console.log(filter);
            // generate a GetFeature request
            const featureRequest = new WFS().writeGetFeature({
                featureTypes: [featureType],
                outputFormat: "application/json",
                filter: filter,
            });

            // then post the request and add the received features to a layer
            fetch(baseUrl + "wfs", {
                method: "POST",
                body: new XMLSerializer().serializeToString(featureRequest),
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (json) {
                    console.log(json);
                    if (json.features.length == 0) {
                        console.log("查询无结果");
                        return false;
                    }
                    console.log(json);
                    const features = new GeoJSON().readFeatures(json);
                    that.vectorSource.addFeatures(features);
                });
        },
        findPlanAirport(name) {
            this.vectorSource.clear();
            let that = this;
            var filter = equalToFilter("name", name);
            // generate a GetFeature request
            const featureRequest = new WFS().writeGetFeature({
                featureTypes: ["airport"],
                outputFormat: "application/json",
                filter: filter,
            });

            // then post the request and add the received features to a layer
            fetch(baseUrl + "wfs", {
                method: "POST",
                body: new XMLSerializer().serializeToString(featureRequest),
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (json) {
                    if (json.features.length == 0) {
                        console.log("查询无结果");
                        return false;
                    }
                    console.log(json);
                    const features = new GeoJSON().readFeatures(json);
                    // that.vectorSource.addFeatures(features);
                    that.map.getView().setZoom(9);
                    var center = new getCenter(
                        features[0].getGeometry().getExtent()
                    );
                    that.map.getView().setCenter(center);
                });
        },
    },
};
</script>
<style>
.tool-wrapper {
    position: absolute;
    z-index: 9;
    right: 30px;
    top: 30px;
    background-color: white;
    padding: 0 4px 0 6px;
    border-radius: 4px;
}

.tool {
    font-size: 28px;
    display: inline-block;
}

.tool:hover {
    color: rgba(209, 52, 214, 0.904);
    cursor: pointer;
}

.tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
}
.tooltip-measure {
    opacity: 1;
    font-weight: bold;
}
.tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
}
.tooltip-measure:before,
.tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}
.tooltip-static:before {
    border-top-color: #ffcc33;
}
</style>